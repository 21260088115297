import PropTypes from 'prop-types';
import React, {Component} from 'react';
import View from 'spider/store/View';
import {Route, Switch} from 'react-router-dom';
import Load from './Loadable';

const Login = Load(() => import('../screen/Login'));
const NotFound = Load(() => import('../container/NotFound'));

/**
 * If a user is nog logged into INSZO, they still need to be able to do more than only logging in. Some routing is
 * requierd, hence a different router
 */
export default class Router extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(View).isRequired,
    };

    // react-router is a bit too verbose so I made a shorthand
    route = Screen => {
        return rProps => <Screen {...rProps} viewStore={this.props.store}/>;
    };

    render() {
        return (
            <Switch>
                <Route path="/login/:token" render={this.route(Login)} />
                <Route path="/login/" render={this.route(Login)} />
                <Route render={this.route(NotFound)}/>
            </Switch>
        );
    }
}
