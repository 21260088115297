import * as Sentry from '@sentry/react';
// If taking advantage of automatic instrumentation (highly recommended)
import {Integrations as TracingIntegrations} from "@sentry/tracing";


// Patch our logic for now
// Only works well with FM set-up and needs more work
//   // Compatibilty with http://localhost/hn.sms.test/ get the hostname
//   const parser = document.createElement('a');
//   parser.href = window.location.href.replace('localhost', '');
//   const hostname = parser.hostname.split('.');
//
//   // Get the hostname of the url, if the length is 4 for example  de.react.incenova.com use the 2nd (react) otherwise
//   // aka in the normal case use the first part of the host domain (hn in case of hn.incenova.com)
//   const subdomain = hostname.length === 4 ? hostname[1] : hostname[0];

// check if the url contains staging or uat, if so configure that as enviroment otherwise assume it is production
const enviroment = window.location.host.includes('staging') ? 'staging' : window.location.host.includes('uat') ? 'uat' : 'production'

// Variable to track if sentry should be enabled in the frontend
export const sentryEnabled = process.env.REACT_APP_CY_FRONTEND_SENTRY_DSN && process.env.REACT_APP_CY_FRONTEND_SENTRY_DSN.length > 0
// export const sentryEnabled = false

// Initialize the sentry intergration, taking all these proccess env variables from the cy/pkg build frontend step
// these can be set in the .env file on the server where
export default function configureSentry(viewStore ,history) {
    if(sentryEnabled){
        Sentry.init({
          dsn: process.env.REACT_APP_CY_FRONTEND_SENTRY_DSN,
            integrations: [
                new TracingIntegrations.BrowserTracing({
                  routingInstrumentation: Sentry.reactRouterV4Instrumentation(history),
                }),
              ],
            tracesSampleRate: parseFloat(process.env.REACT_APP_CY_FRONTEND_SENTRY_TRACE_RATE),
            enviroment: enviroment
        });
      // setTagSentry('client', subdomain)
    }
}

// Sets the user to be used by sentry, if the currrentUser provided is actually the null value this implies
// the user has just logged out and the sentry user is removed
export function setUserSentry(currentUser) {
  if (sentryEnabled) {
    if (currentUser !== null) {
      // Set the sentry frontend user to be the user comming from the bootstrap
        Sentry.setUser({
          id: currentUser.id,
          email: currentUser.email,
        })

    } else {
      Sentry.configureScope(scope => scope.setUser(null));
    }
  }
}

export function setTagSentry(tag, value) {
  if (sentryEnabled) {
      Sentry.setTag(tag, value)
  }
}

export function setContextSentry(context, value) {
  if (sentryEnabled) {
      Sentry.setContext(context, value)
  }
}
