import React from 'react';
import BaseLogin from 'spider/semantic-ui/Login';

export default class BlankLogin extends BaseLogin {

    renderForm() {
        return (
            <>
                Het is niet mogelijk om direct vanuit de Hoover in te loggen. <br /><br />

                <a href="https://mijn.inszo.nl/">Ga terug naar het MijnINSZO portaal</a>
            </>
        );
    }

}
