import { tint } from 'polished';

// const primaryColor = '#2d303b';
// const tintedPrimaryColor = tint(0.15, primaryColor);
// const buttonColor = '#48b4ca';
// const dangerColor = '#dc0818';
// const warningColor = '#ffc107';

const primaryColor = '#102770';
const secondaryColor = '#A94FAE';
const tintedPrimaryColor = tint(0.15, primaryColor);
const buttonColor = primaryColor;
const dangerColor = '#dc0818';
const warningColor = '#ffc107';
const blueLightColor = '#646198';

export const theme = {
    primaryColor,
    secondaryColor,
    tintedPrimaryColor,
    buttonColor,
    dangerColor,
    warningColor,
    blueLightColor
};
