import { autorun, toJS, set } from 'mobx';
import { omit, pick, fromPairs, isEmpty } from 'lodash';
import { generateV2Url, V2_URL_PREFIX } from '../component/Navigation';
import { trimStart } from 'lodash';

const PAGINATION_KEYS = [
    'limit',
    'currentPage',
];

let configuredBasename = '';
let afterSaveOptions = {};

// V2 only projects need to set this...
export function configureBasename(b) {
    configuredBasename = b;
}

export function configureAfterSave(options) {
    afterSaveOptions = options;
}

function urlParamsToStoreState(urlParams) {
    const stateParams = pick(urlParams, PAGINATION_KEYS);

    PAGINATION_KEYS.forEach(k => {
        if (stateParams[k] !== undefined) {
            const parsed = parseInt(stateParams[k]);

            if (isFinite(parsed)) {
                stateParams[k] = parsed;
            }
        }
    });

    return stateParams;
}

/**
 * Keep histery/url state in sync with store params, so when you refresh the
 * page, the store params are reset.
 */
export default function({ store, defaultParams }) {
    store.params = defaultParams;

    let urlParams = new URLSearchParams(window.location.search);
    urlParams = fromPairs(Array.from(urlParams.entries()));

    if (isEmpty(urlParams)) {
        set(store, 'params', omit(defaultParams, PAGINATION_KEYS));
        Object.assign(store.__state, pick(defaultParams, PAGINATION_KEYS));
    } else {
        set(store, 'params', omit(urlParams, PAGINATION_KEYS));
        Object.assign(store.__state, urlParamsToStoreState(urlParams));
    }

    store.updateUrlParams = () => {
        const p = new URLSearchParams(Object.assign(pick(store.__state , PAGINATION_KEYS), toJS(store.params)));
        window.history.replaceState({}, '', `${window.location.pathname}?${p}`);
    }

    return autorun(() => {
        const p = new URLSearchParams(Object.assign(toJS(pick(store.__state, PAGINATION_KEYS)), toJS(store.params)));
        window.history.replaceState({}, '', `${window.location.pathname}?${p}`);
    });
}

export function urlEncodeObject(params) {
    const urlSearchParams = new URLSearchParams();

    Object.keys(params).forEach(k => {
        urlSearchParams.append(k, params[k]);
    });

    return urlSearchParams.toString();
}

export function decodeUrl(key) {
    let urlParams = new URLSearchParams(window.location.search);
    urlParams = fromPairs(Array.from(urlParams.entries()));

    if (key === undefined) {
        return urlParams;
    } else {
        return urlParams[key];
    }
}

// Kinda a mess atm...
export function encodeUrl(store, givenBaseUrl) {
    const baseUrl = givenBaseUrl === undefined ? (configuredBasename || generateV2Url('/')) : givenBaseUrl;

    // Backwards compatibility with /v2 prefixes.
    const pathname = window.location.href.split(baseUrl)[1] || window.location.href.split(baseUrl.replace(V2_URL_PREFIX, ''))[1];

    const p = urlEncodeObject(Object.assign(toJS(pick(store.__state, PAGINATION_KEYS)), toJS(store.params)));
    const pageUrl = `${pathname}?${p}`;
    const encodedUri = encodeURIComponent('/' + trimStart(pageUrl, '/'));

    return encodedUri;
}

export function encodeUrlByPathname(store, pathname) {
    const p = urlEncodeObject(Object.assign(toJS(pick(store.__state, PAGINATION_KEYS)), toJS(store.params)));
    const pageUrl = `${pathname}?${p}`;
    const encodedUri = encodeURIComponent('/' + trimStart(pageUrl, '/'));

    return encodedUri;
}

export function afterSave(history, viewStore, model) {
    return function(options = {}) {
        const { goTo, goBack = true, createUrl = '/create' } = Object.assign({}, afterSaveOptions, options);
        const next = decodeUrl('next');
        const path = window.location.pathname.replace(configuredBasename, '');
        const newPath = model ? path.replace(createUrl, `/${model.id}/edit`) : path;

        viewStore.showSaveNotification();

        if (path !== newPath) {
            history.replace(`${newPath}`);
        }
        if (goBack) {
            history.push(goTo || next);
        }
    }
}
