import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {TopMenu, MenuRow, NavItem} from 're-cy-cle';
import {withRouter} from 'react-router-dom';
import { loginAuthentication } from 'container/App';
import styled from 'styled-components';

const HiddenMenuRow = styled(MenuRow)`
    &&& {
        height: 0;
    }
`;

@withRouter
@observer
export default class AppHeader extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
    };

    @observable debug = false;

    toggleDebug = () => {
        this.debug = !this.debug;

        if (this.debug) {
            localStorage.setItem('debug', true);
        } else {
            localStorage.removeItem('debug');
        }
    }

    constructor(...args) {
        super(...args);
        this.debug = !!localStorage.getItem('debug');
    }

    hasPermission = perms => {
        return this.props.store.currentUser.hasPermission(perms);
    };

    renderAssets = () => {
        return (
            <>
                <NavItem
                    title={t('nav.assets.users')}
                    to="/assets/user/overview"
                    activePath="/assets/user/"
                />
                <NavItem
                    title={t('nav.assets.globalValues')}
                    to="/assets/global-value/overview"
                    activePath="/assets/global-value/"
                />
                <NavItem
                    title={t('nav.assets.globalFiles')}
                    to="/assets/global-file/overview"
                    activePath="/assets/global-file/"
                />
            </>
        );
    };

    renderAudit = () => {
        return (
            <>
                <NavItem title={t('nav.audit.accessLog')} to="/audit/access-log/overview" activePath="/audit/access-log/"/>
            </>
        );
    }

    renderAccount = () => {
        return (
            <>
                <NavItem title={t('nav.account.account')} to="/account/details"/>
                <NavItem title={t('nav.account.changelog')} to="/account/changelog"/>
            </>
        );
    };

    renderHooverboard = () => {
        return (
            <>
                <NavItem title={t('nav.hooverboard.hooverboard')} to="/hooverboard"/>
            </>
        )
    }

    render() {
        const {store, location} = this.props;
        const {currentUser} = store;

        if (!this.props.store.isAuthenticated ||
            (currentUser.groupNames === undefined && !currentUser.isSuperuser) ||
            (loginAuthentication.test(location.pathname))

        ) {
            return (<TopMenu>
                        {/* Fool an app that it has top menu bar */}
                        <HiddenMenuRow/>
                        <MenuRow/>
                    </TopMenu>
                );
        }

        return (
            <>
                {this.renderMenu()}
            </>
        );
    }

    renderMenu() {
        const {currentUser} = this.props.store;
        return (currentUser.isSuperuser || currentUser.isAdmin) ? this.renderAdminMenu() : this.renderCaregiverMenu();
    }

    renderAdminMenu = () => {
        return (
            <TopMenu>
                {/* Fool an app that it has top menu bar */}
                <HiddenMenuRow/>
                <MenuRow>
                    {/*{this.renderAccount()}*/}
                    {this.renderHooverboard()}
                    {this.renderAudit()}
                    {/*{this.renderAssets()}*/}
                </MenuRow>
            </TopMenu>
        );
    }

    renderCaregiverMenu = () => {
        return (
            <TopMenu>
                {/* Fool an app that it has top menu bar */}
                <HiddenMenuRow/>
                <MenuRow>
                    {/* <NavItem title={t("nav.main.hovoerboard")} to="/hooverboard/" activePath="/hooverboard/" /> */}
                </MenuRow>
            </TopMenu>
        );
    }
}
